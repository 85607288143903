@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&family=Kantumruy+Pro:ital,wght@0,100..700;1,100..700&family=Noto+Sans+JP:wght@100..900&family=Ysabeau:ital,wght@0,1..1000;1,1..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Mono:ital,wght@0,300..700;1,300..700&display=swap');
body {
  margin: 0;
  font-family: "Noto Sans JP", sans-serif ;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: oklch(var(--b2));
}

.counter div span{
  font-family: "Red Hat Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 500;
  transform: scaleX(0.8);
  transform: scaleY(1.1);
  
  font-style: normal;
}

.content-container{
  height: 80vh;
  padding-top: 7%;
}

.glowing-text,
.glowing-text2,
.glowing-text3{
  background-image: linear-gradient(to right, #7006E6 0 9%, #F1B109 80% 100%);
  background-clip: text;
  color: transparent;
  text-shadow: 0 0 11px rgba(255, 41, 41, 0.493);
  transition: 0.2s all;
}

.glowing-text{
  background-image: linear-gradient(to right, red 0 33%, orange 50% 100%);
  text-shadow: 0 0 15px rgba(255, 0, 0, 0.507);
}

.glowing-text3{
  background-image: linear-gradient(to right, red , orange);
}

.glowing-text3:hover{
  text-shadow: 0 0 20px rgba(255, 41, 41, 0.979);
  font-weight: 500;
}

.glowing-text2:hover{
  text-shadow: 0 0 20px rgba(255, 41, 41, 0.979);
  font-weight: 800;
}

.right-arrow{
  background-clip: text;
  color: rgb(255, 196, 0);
  display: inline-block;
  font-size: 36px;
  margin-bottom: -6px;
  margin-left: -8px;
  transition: 0.2s all;
}

.glowing-text3:hover ~ .hover-icon{
  fill: url(#orange-gradient)
}

.hover-icon:hover{
  fill: url(#orange-gradient)
}

.glowing-text2:hover .right-arrow{
  text-shadow: 0 0 20px rgba(255, 41, 41, 0.979);
  transform: scale(120%);
}

.glowing-text2:active .right-arrow{
  transform: scale(80%);
}

.steps-container{
  max-width: 750px;
}


canvas {
  margin: 30px auto;
  background: #fff;
  padding: 30px;
  border-style: solid;
  border-width: 15px;
  border-top-color: #333333;
  border-right-color: black;
  border-bottom-color: #333333;
  border-left-color:black;
  box-shadow: 2px 2px 4px rgba(0,0,0,.6);
}
 
.step-success::after,
.step-success::before{
  background-color: white !important;
}




